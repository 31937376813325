<template>
  <div class="meters">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="can('meter-create')"
          color="primary"
          class="mb-5"
          @click="showAddMeterDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiTablePlus }}
          </v-icon>
          Add Meter
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isMetersRefreshing"
                @click="refreshMeters()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :show-date-filter="false"
                :show-meter-valve-status-filter="true"
                @filter="applyFilters"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="meters"
        :loading="isMetersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="meters.length > 0">
            <tr
              v-for="(meter, index) in items"
              :key="meter.id"
              style="cursor: pointer"
              @click="onMeterClick(meter.id)"
            >
              <td>
                <span
                  v-if="meter.user"
                  class="primary--text font-weight-medium"
                >{{ meter.user.account_number }}</span>
              </td>
              <td>{{ meter.number }}</td>
              <td>{{ meter.location }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="{ success: meter.mode === 1, secondary: meter.mode === 0 }"
                >
                  {{ meter.mode | formatMeterMode }}
                </v-chip>
              </td>
              <td>
                <span v-if="meter.mode == 1">{{ meter.type.name }}</span>
                <span v-else>Not applicable</span>
              </td>
              <td>
                <span
                  v-if="meter.user"
                  class="primary--text font-weight-medium"
                >{{ meter.user.name }}</span>
                <span v-else>No customer</span>
              </td>
              <td>
                <span v-if="meter.type && meter.type.name == 'Prepaid'">
                  Not applicable
                </span>
                <span v-else>
                  {{ meter.last_reading }}
                </span>
              </td>
              <td @click.stop>
                <span v-if="meter.mode == 1 && meter.type.name != 'Prepaid'">
                  <v-switch
                    v-model="meter.valve_status"
                    inset
                    :loading="isToggleValveSwitchLoading && index == switchIndex"
                    :disabled="isToggleValveSwitchLoading && index == switchIndex"
                    :false-value="0"
                    :true-value="1"
                    :label="meter.valve_status ? 'Open' : 'Closed'"
                    @change="showToggleValveConfirmDialog(meter.id, meter.valve_status, index)"
                  ></v-switch>
                </span>
                <span v-else>Not applicable</span>
              </td>
              <td v-show="selectedStation === 'all'">
                {{ meter.station.name }}
              </td>
              <td
                v-if="can('meter-edit') || can('meter-delete')"
                @click.stop
              >
                <v-tooltip
                  v-if="can('meter-edit')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editMeter(meter)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('meter-delete')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteMeterDialog(meter.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isMetersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No meters found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
    <add-meter-dialog
      :show-dialog="showAddMeterDialog"
      :meter="meter"
      @close="
        showAddMeterDialog = false
        meter = {}
      "
      @meter-added="getMeters()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteMeter()"
    />
    <confirm-dialog
      :show-dialog="showConfirmToggleValveDialog"
      :is-agree-button-loading="isToggleValveSwitchLoading"
      :message="'Are you sure you want to switch off valve of this meter?'"
      :agree-text="'Switch Off'"
      @cancel="cancelToggleValveStatusUpdate()"
      @agree="updateValveStatus()"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiTablePlus,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import AddMeterDialog from '@/components/dialogs/AddMeterDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    AddMeterDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
    TableFilter,
    RefreshButton,
    DataTablePagination,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddMeterDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      showConfirmToggleValveDialog: false,
      isToggleValveSwitchLoading: false,
      isMetersRefreshing: false,
      switchIndex: -1,
      toDelete: null,
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Account Number',
          value: 'account_number',
          sortable: false,
        },
        {
          text: 'Meter Number',
          align: 'start',
          value: 'number',
          sortable: false,
        },
        { text: 'Location', value: 'location', sortable: false },
        {
          text: 'Mode',
          value: 'mode',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
        },
        { text: 'Customer', value: 'user', sortable: false },
        {
          text: 'Last reading',
          value: 'last_reading',
          sortable: false,
        },
        {
          text: 'Valve Status',
          value: 'valve_status',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'users.account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'users.account_number',
        },
        {
          text: 'Meter number',
          value: 'number',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Customer',
          value: 'users.name',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiTablePlus,
      },
      meter: {},
      meterId: this.$route.params.id,
      meters: [],
      valveStatusToUpdate: {
        meterId: '',
        status: '',
        index: '',

      },
      isMetersLoading: true,
      metersUrl: 'meters',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Meters',
          disabled: false,
          to: { name: 'meters' },
        },
      ],
      filters: {
        meterValveStatus: [],
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.selectedStation}|${this.userUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getMeters()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getMeters()
      }
    },
    selectedStation: {
      handler(newVal, oldVal) {
        if (newVal === 'all') {
          if (!this.headers.some(header => header.value === 'station')) {
            const stationHeader = {
              text: 'Station',
              value: 'station',
              sortable: false,
            }
            this.headers.push(stationHeader)
          }
        } else {
          this.headers = this.headers.filter(header => header.value !== 'station')
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!(this.can('meter-edit') && this.can('meter-delete'))) {
      this.removeActionsHeader()
    }
    this.getMeters()
    if (this.meterId) {
      this.breadcrumbs.pop()
      this.breadcrumbs.push(
        {
          text: 'Meters',
          disabled: false,
          to: { name: 'meters' },
        },
        {
          text: this.meterId,
          disabled: true,
          to: { name: 'meter-details' },
        },
      )
    }
  },
  methods: {
    getMeters: _.debounce(function () {
      this.isMetersLoading = true
      const valveStatus = JSON.stringify(this.filters.meterValveStatus)
      axios
        .get(
          `${this.metersUrl}?station_id=${this.selectedStation}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&valveStatus=${valveStatus}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
        )
        .then(response => {
          this.meters = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.isMetersLoading = false
          this.isMetersRefreshing = false
        })
        .catch(error => {
          this.isMetersLoading = false
          this.isMetersRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshMeters() {
      this.isMetersRefreshing = true
      this.getMeters()
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMeters()
    },
    showToggleValveConfirmDialog(meterId, status, index) {
      this.valveStatusToUpdate.meterId = meterId
      this.valveStatusToUpdate.status = status
      this.valveStatusToUpdate.index = index
      if (this.valveStatusToUpdate.status === 1) {
        this.updateValveStatus()

        return
      }
      this.showConfirmToggleValveDialog = true
    },
    cancelToggleValveStatusUpdate() {
      this.showConfirmToggleValveDialog = false
      this.meters[this.valveStatusToUpdate.index].valve_status = this.boolToInt(!this.meters[this.valveStatusToUpdate.index].valve_status)
    },
    boolToInt(bool) {
      return bool * 1
    },
    updateValveStatus() {
      this.showConfirmToggleValveDialog = false
      this.isToggleValveSwitchLoading = true
      this.switchIndex = this.valveStatusToUpdate.index
      let action = 'closed'
      if (this.valveStatusToUpdate.status === 1) {
        action = 'opened'
      }
      axios
        .put(`valve-status/${this.valveStatusToUpdate.meterId}`, {
          valve_status: this.valveStatusToUpdate.status,
        })
        .then(response => {
          this.switchIndex = -1
          this.isToggleValveSwitchLoading = false
          this.$notification.success(`Valve for meter ${response.data} has been ${action} successfully`)
        })
        .catch(error => {
          this.cancelToggleValveStatusUpdate()
          this.switchIndex = -1
          this.isToggleValveSwitchLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    editMeter(meter) {
      this.meter = meter
      this.showAddMeterDialog = true
    },
    showDeleteMeterDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteMeter() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`meters/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Meter deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getMeters()
        })
        .catch(error => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.$notification.error(error.response.data.message)
        })
    },
    onMeterClick(meterId) {
      this.$router.push({ name: 'more-meter-details', params: { id: meterId } })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getMeters()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getMeters()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
  },
}
</script>
